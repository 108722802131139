import React from 'react'
import Contactt from "../components/Contact/contact"

function Contact() {
  return (
    <div>

      <Contactt />
    </div>
  )
}

export default Contact