import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Row, Col, Typography, Skeleton, Modal, Button } from 'antd';
import { useTranslation } from "react-i18next";
const { Title, Paragraph } = Typography;

const NewsDisplay = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axios.get('https://bolesubcitypointer.api.ethioalert.com/api/news');
      setNews(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  };

  const showModal = (newsItem) => {
    setCurrentNews(newsItem);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentNews(null);
  };

  return (
    <div className="container mx-auto p-4">
      <Title level={2} className="text-center text-xl lg:text-4xl mb-8">{t('Latest News')}</Title>
      <Row gutter={[16, 16]}>
        {loading ? (
          Array.from({ length: 6 }).map((_, index) => (
            <Col xs={24} sm={12} md={8} lg={6} key={index}>
              <Skeleton active>
                <Card loading={true} />
              </Skeleton>
            </Col>
          ))
        ) : (
          news.map((item) => (
            <Col xs={24} sm={12} md={8} lg={6} key={item._id}>
              <Card
                hoverable
                cover={
                  <img
                    alt={item.title}
                    src={`https://bolesubcitypointer.api.ethioalert.com/${item.image}`}
                    className="object-cover h-48"
                  />
                }
                className="rounded-lg shadow-lg"
              >
                <Title level={4} className="truncate">{item.title}</Title>
                <Paragraph ellipsis={{ rows: 2 }}>{item.description}</Paragraph>
                <Button type="primary" onClick={() => showModal(item)}>
                  Read More
                </Button>
              </Card>
            </Col>
          ))
        )}
      </Row>
      {currentNews && (
        <Modal
          title={currentNews.title}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <img
            alt={currentNews.title}
            src={`https://bolesubcitypointer.api.ethioalert.com/${currentNews.image}`}
            className="w-full h-64 object-cover mb-4"
          />
          <Paragraph>{currentNews.description}</Paragraph>
        </Modal>
      )}
    </div>
  );
};

export default NewsDisplay;
