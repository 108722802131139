import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, message, Checkbox } from 'antd';
import axios from 'axios';
import { motion } from 'framer-motion'; // Import Framer Motion
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS CSS
import { useTranslation } from "react-i18next";
const { Title, Text } = Typography;
const { TextArea } = Input;

const FeedbackPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 2000 });
  }, []);

  const onFinish = async (values) => {
    if (!disclaimerAccepted) {
      message.error('Please accept the disclaimer before submitting.');
      return;
    }

    setLoading(true);
    try {
      await axios.post('https://bolesubcitypointer.api.ethioalert.com/api/feedback', values);
      message.success('Feedback submitted successfully');
      form.resetFields();
      setDisclaimerAccepted(false); // Reset checkbox state
    } catch (error) {
      console.error('Error submitting feedback:', error);
      message.error('Failed to submit feedback');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bg-gray-200 min-h-screen flex items-center justify-center p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full" data-aos="fade-up">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <Title level={2} className="text-center mb-4">{t('We Value Your Feedback')}</Title>
          <Form
            form={form}
            name="feedback"
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              name="name"
              label={<Text strong>{t('Name')}</Text>}
              rules={[{ required: true, message: 'Please enter your name' }]}
            >
              <Input placeholder="Your Name" />
            </Form.Item>
            <Form.Item
              name="email"
              label={<Text strong>{t('Email')}</Text>}
              rules={[{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input placeholder="Your Email" />
            </Form.Item>
            <Form.Item
              name="message"
              label={<Text strong>{t('Message')}</Text>}
              rules={[{ required: true, message: 'Please enter your message' }]}
            >
              <TextArea rows={4} placeholder="Your Message" />
            </Form.Item>
            <Form.Item>
              <Checkbox
                checked={disclaimerAccepted}
                onChange={(e) => setDisclaimerAccepted(e.target.checked)}
              >
                {t('I have read and accept the disclaimer')}
              </Checkbox>
            </Form.Item>
            <div className="mb-4 text-sm text-gray-700">
              <span className="bg-red-600 text-white font-bold p-1 rounded-sm mr-2">{t('Disclaimer')}</span> {t('The feedback provided is true and accurate to the best of my knowledge.')}
            </div>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} className="w-full mt-4" disabled={!disclaimerAccepted}>
                {t('Submit Feedback')}
              </Button>
            </Form.Item>
          </Form>
        </motion.div>
      </div>
    </section>
  );
};

export default FeedbackPage;
