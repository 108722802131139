import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Modal } from 'antd';

const FeedbackManager = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState(null);

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  const fetchFeedbacks = async () => {
    try {
      const response = await axios.get('https://bolesubcitypointer.api.ethioalert.com/api/feedback');
      setFeedbacks(response.data);
    } catch (error) {
      console.error('Error fetching feedbacks:', error);
    }
  };

  const handleView = (record) => {
    setCurrentFeedback(record);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    setIsViewModalVisible(false);
    setCurrentFeedback(null);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => handleView(record)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Table columns={columns} dataSource={feedbacks} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title="View Feedback"
        visible={isViewModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>Close</Button>,
        ]}
      >
        {currentFeedback && (
          <div>
            <p><strong>Name:</strong> {currentFeedback.name}</p>
            <p><strong>Email:</strong> {currentFeedback.email}</p>
            <p><strong>Message:</strong></p>
            <p>{currentFeedback.message}</p>
            <p><strong>Created At:</strong> {new Date(currentFeedback.createdAt).toLocaleString()}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default FeedbackManager;
