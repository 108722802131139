import React, { useState, useEffect } from 'react';
import { Card, Pagination, Modal, Button, Image } from 'antd';
import { motion } from 'framer-motion';
import axios from 'axios';
import 'tailwindcss/tailwind.css';
import Loader from '../../components/Loader'; // Import the Loader component

const Gallery = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewDescription, setPreviewDescription] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Add loading state
  const itemsPerPage = 12;
  const imageSize = { width: 300, height: 200 }; // Define fixed size for images

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await axios.get('https://bolesubcitypointer.api.ethioalert.com/api/gallery-images');
        setGalleryItems(response.data);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching gallery items:', error);
        setLoading(false); // Ensure loading is set to false even on error
      }
    };

    fetchGalleryItems();
  }, []);

  // Calculate the indexes of the items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = galleryItems.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const openPreview = (index) => {
    setPreviewImage(currentItems[index].image);
    setPreviewDescription(currentItems[index].description);
    setPreviewVisible(true);
    setCurrentIndex(index);
  };

  const closePreview = () => {
    setPreviewVisible(false);
  };

  const showNext = () => {
    if (currentIndex < currentItems.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setPreviewImage(currentItems[currentIndex + 1].image);
      setPreviewDescription(currentItems[currentIndex + 1].description);
    }
  };

  const showPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setPreviewImage(currentItems[currentIndex - 1].image);
      setPreviewDescription(currentItems[currentIndex - 1].description);
    }
  };

  return (
    <div className="container mx-auto p-4 lg:p-8 bg-blue-50 min-h-screen">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <Loader /> {/* Show loader while data is being fetched */}
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {currentItems.map((item, index) => (
              <motion.div
                key={item._id}
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, type: "spring", stiffness: 120 }}
                className="flex justify-center"
              >
                <Card
                  cover={
                    <div
                      className="relative w-full cursor-pointer"
                      style={{ height: `${imageSize.height}px` }}
                      onClick={() => openPreview(index)}
                    >
                      <img
                        alt={item.description}
                        src={`https://bolesubcitypointer.api.ethioalert.com/${item.image}`}
                        className="w-full h-full object-cover rounded-lg"
                        style={{ height: `${imageSize.height}px` }}
                      />
                    </div>
                  }
                  className="w-full max-w-xs bg-[#0a4275] text-gray-800 shadow-lg rounded-lg hover:shadow-2xl hover:scale-105 transition-transform"
                  hoverable
                  style={{ borderRadius: '10px' }}
                >
                  <Card.Meta
                    description={
                      <p className="text-white text-center">
                        {item.description}
                      </p>
                    }
                    className="text-center mt-4"
                  />
                </Card>
              </motion.div>
            ))}
          </div>

          {/* Modal for image preview */}
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={closePreview}
            width="80%"
            centered
            closeIcon={<Button type="text" className="text-red-600 text-2xl font-bold" onClick={closePreview}>X</Button>}
            className="relative"
          >
            <div className="flex justify-between items-center">
              <Button
                type="text"
                onClick={showPrevious}
                disabled={currentIndex === 0}
                className="text-red-600 font-bold text-3xl"
              >
                &lt;
              </Button>
              <div className="flex flex-col items-center flex-grow">
                <Image
                  src={`https://bolesubcitypointer.api.ethioalert.com/${previewImage}`}
                  alt="Preview"
                  preview={false} // Disable default Ant Design preview
                  className="mb-4"
                  style={{ width: `${imageSize.width}px`, height: `${imageSize.height}px`, objectFit: 'cover' }}
                />
                <p className="text-gray-600 text-center font-bold text-sm md:text-xl lg:text-2xl">{previewDescription}</p>
              </div>
              <Button
                type="text"
                onClick={showNext}
                disabled={currentIndex === currentItems.length - 1}
                className="text-3xl text-red-600 font-bold"
              >
                &gt;
              </Button>
            </div>
          </Modal>

          <div className="flex justify-center mt-8">
            <Pagination
              current={currentPage}
              total={galleryItems.length}
              pageSize={itemsPerPage}
              onChange={onPageChange}
              showSizeChanger={false}
              className="bg-gray-100 rounded-lg p-2 shadow-md"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Gallery;
