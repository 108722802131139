import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Modal } from 'antd';

const ComplaintManager = () => {
  const [complaints, setComplaints] = useState([]);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentComplaint, setCurrentComplaint] = useState(null);

  useEffect(() => {
    fetchComplaints();
  }, []);

  const fetchComplaints = async () => {
    try {
      const response = await axios.get('https://bolesubcitypointer.api.ethioalert.com/api/complaints');
      setComplaints(response.data);
    } catch (error) {
      console.error('Error fetching complaints:', error);
    }
  };

  const handleView = (record) => {
    setCurrentComplaint(record);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    setIsViewModalVisible(false);
    setCurrentComplaint(null);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'complaintName',
      key: 'complaintName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Complaint Detail',
      dataIndex: 'complaintDetail',
      key: 'complaintDetail',
      render: (text) => text.substring(0, 20) + (text.length > 20 ? '...' : ''),
    },
    {
      title: 'Date Submitted',
      dataIndex: 'complaintFiled',
      key: 'complaintFiled',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Evidence',
      dataIndex: 'evidence',
      key: 'evidence',
      render: (evidence) => {
        if (!evidence) return 'No Evidence';
        const baseUrl = 'https://bolesubcitypointer.api.ethioalert.com/';
        if (evidence.endsWith('.jpg') || evidence.endsWith('.png')) {
          return <img src={`${baseUrl}${evidence}`} alt="Evidence" style={{ maxWidth: '100px', maxHeight: '100px' }} />;
        } else if (evidence.endsWith('.mp4')) {
          return <video controls src={`${baseUrl}${evidence}`} style={{ maxWidth: '100px', maxHeight: '100px' }} />;
        } else if (evidence.endsWith('.mp3')) {
          return <audio controls src={`${baseUrl}${evidence}`} />;
        } else if (evidence.endsWith('.pdf')) {
          return <iframe src={`${baseUrl}${evidence}`} title="PDF Preview" style={{ width: '100px', height: '100px' }} />;
        } else {
          return <a href={`${baseUrl}${evidence}`} target="_blank" rel="noopener noreferrer">Preview</a>;
        }
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => handleView(record)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Table columns={columns} dataSource={complaints} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title="View Complaint"
        visible={isViewModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>Close</Button>,
        ]}
      >
        {currentComplaint && (
          <div>
            <p><strong>Name:</strong> {currentComplaint.complaintName}</p>
            <p><strong>Phone Number:</strong> {currentComplaint.phoneNumber}</p>
            <p><strong>Complaint Detail:</strong> {currentComplaint.complaintDetail}</p>
            <p><strong>Date Submitted:</strong> {new Date(currentComplaint.complaintFiled).toLocaleString()}</p>
            {currentComplaint.evidence && (
              <div>
                <strong>Evidence:</strong>
                <div>
                  {currentComplaint.evidence.endsWith('.jpg') || currentComplaint.evidence.endsWith('.png') ? (
                    <img src={`https://bolesubcitypointer.api.ethioalert.com/${currentComplaint.evidence}`} alt="Evidence" style={{ maxWidth: '100%', maxHeight: 300 }} />
                  ) : currentComplaint.evidence.endsWith('.mp4') ? (
                    <video controls src={`https://bolesubcitypointer.api.ethioalert.com/${currentComplaint.evidence}`} style={{ maxWidth: '100%', maxHeight: 300 }} />
                  ) : currentComplaint.evidence.endsWith('.mp3') ? (
                    <audio controls src={`https://bolesubcitypointer.api.ethioalert.com/${currentComplaint.evidence}`} style={{ maxWidth: '100%', maxHeight: 300 }} />
                  ) : currentComplaint.evidence.endsWith('.pdf') ? (
                    <iframe src={`https://bolesubcitypointer.api.ethioalert.com/${currentComplaint.evidence}`} title="PDF Preview" style={{ width: '100%', height: 500 }} />
                  ) : (
                    <a href={`https://bolesubcitypointer.api.ethioalert.com/${currentComplaint.evidence}`} target="_blank" rel="noopener noreferrer">Preview</a>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ComplaintManager;
