import React, { useState, useEffect } from 'react';
import { Upload, Button, Input, Form, message, Checkbox } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useTranslation } from "react-i18next";
import AOS from 'aos'; // Import AOS
import { motion } from 'framer-motion'; // Import Framer Motion
import 'aos/dist/aos.css'; // Import AOS CSS

const ComplaintForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 2000 });
  }, []);

  const handleSubmit = async (values) => {
    if (!disclaimerAccepted) {
      message.error('Please accept the disclaimer before submitting.');
      return;
    }

    const formData = new FormData();
    formData.append('complaintName', values.name);
    formData.append('phoneNumber', values.phone);
    formData.append('institution', values.institution);
    formData.append('complaintDetail', values.detail);
    
    // Append each file in the fileList to formData
    fileList.forEach(file => {
      formData.append('evidence', file.originFileObj);
    });

    try {
      await axios.post('https://bolesubcitypointer.api.ethioalert.com/api/complaints', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Complaint submitted successfully!');
      form.resetFields();
      setFileList([]); // Clear fileList after successful submission
      setDisclaimerAccepted(false); // Reset checkbox state
    } catch (error) {
      console.error('Error submitting complaint:', error);
      message.error('Failed to submit complaint. Please try again.');
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadProps = {
    beforeUpload: () => false, // Prevent automatic upload
    onChange: handleChange,
    fileList,
  };

  return (
    <section className="bg-blue-50 dark:bg-slate-800 py-16">
      <div className="max-w-4xl mx-auto px-4">
        <motion.h2
          className="text-2xl text-[#0a4275] text-center lg:text-4xl font-bold dark:text-white mb-8"
          data-aos="fade-up"
        >
          {t('According to Rule 143/2015, Section 3, Article 9, Sub-Articles 1 and 2 Customer Complaint/Complaint Acceptance Form/Form 001')}
        </motion.h2>
        <motion.div
          className="bg-white p-8 rounded-lg shadow-lg"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Form.Item
              name="name"
              label={t("Complainant's Name , County/City , District, House Number, Telephone Number")}
              rules={[{ required: true, message: 'Please enter your name' }]}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>

            <Form.Item
              name="phone"
              label={t('Name of the institution where the complaint/complaint was submitted')}
              rules={[{ required: true, message: 'Please enter your phone number' }]}
            >
              <Input type="tel" placeholder="Enter your phone number" />
            </Form.Item>

            <Form.Item
              name="institution"
              label={t('Subject of the complaint')}
              rules={[{ required: true, message: 'Please enter the institution' }]}
            >
              <Input placeholder="Enter the institution name" />
            </Form.Item>

            <Form.Item
              name="detail"
              label={t('Briefly, the remedy requested by the complainant')}
              rules={[{ required: true, message: 'Please provide the details of your complaint' }]}
            >
              <Input.TextArea rows={4} placeholder="Provide details about your complaint" />
            </Form.Item>

            <Form.Item
              name="evidence"
              label={t("Final responses/decisions or other relevant information given at each level.")}
            >
              <Upload {...uploadProps} multiple={true} listType="picture">
                <Button icon={<UploadOutlined />}>{t('Upload Evidence')}</Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Checkbox
                checked={disclaimerAccepted}
                onChange={(e) => setDisclaimerAccepted(e.target.checked)}
              >
                {t('I have read and accept the disclaimer')}
              </Checkbox>
            </Form.Item>

            <div className="mb-4 text-sm text-gray-700 dark:text-gray-300">
              <span className='bg-red-600 text-white font-bold p-1 rounded-sm mr-2'>{t('Disclamer')}</span> {t("The petition that I submitted today, according to the accountability regulation 143/2015, part one, paragraph four, by the body authorized to adjudicate in the Hague, by the main audit, by the council's standing committee, by the judges and prosecutors at the Hague administration conference, and whether it has not been seen by the federal institutions. Whether the matter has been investigated or not, the complaints/complaints submitted by the city cabinet, revenue and finance institutions listed above have not been investigated and are not being investigated, and if my submission is false, I confirm that I will be liable according to the provisions of this regulation.")}
            </div>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="w-full bg-blue-800 text-white"
                disabled={!disclaimerAccepted}
              >
                {t('Submit Complaint')}
              </Button>
            </Form.Item>
          </Form>
        </motion.div>
      </div>
    </section>
  );
};

export default ComplaintForm;
