import React from 'react'
import Feedbackk from "../components/Feedback/Feedback"

function Feedback() {
  return (
    <div>

      <Feedbackk />
    </div>
  )
}

export default Feedback