import React from 'react'
import Homee from "../components/Home/Home"
import OfficeDetailsPage from '../components/OfficesDirection/OfficeDetail'

function Home() {
  return (
    <div>

      <Homee />
      <OfficeDetailsPage/>
    </div>
  )
}

export default Home