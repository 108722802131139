// import React from 'react';
// import { FaHome, FaServicestack, FaNewspaper, FaBullhorn, FaComment, FaRegGrinBeam, FaImage } from 'react-icons/fa';
// import logo from "../../assets/logologo.png";
// import { useTranslation } from "react-i18next";

// function ButtonGroup() {
//   const { t } = useTranslation();
//   const buttons = [
//     { label: t('Where do you want to go'), color: "#0000FF", link: "/officedirection", icon: <FaHome /> },
//     { label: t('Services'), color: "#0000FF", link: "/services", icon: <FaServicestack /> },
//     { label: t('News'), color: "#0000FF", link: "/news", icon: <FaNewspaper /> },
//     { label: t('Announcement'), color: "#0000FF", link: "/announcement", icon: <FaBullhorn /> },
//     { label: t('Feedback'), color: "#0000FF", link: "/feedback", icon: <FaComment /> },
//     { label: t('Compliant'), color: "#0000FF", link: "/complaint", icon: <FaRegGrinBeam /> },
//     { label: t('Gallery'), color: "#007bff", link: "/gallery", icon: <FaImage /> },
//   ];

//   return (
//     <div className="p-6 md:p-10 lg:p-14 bg-blue-400 min-h-screen flex flex-col items-center">
//       {/* Logo and Heading Side by Side */}
//       <div className="flex flex-col md:flex-row items-center justify-center mb-10 space-y-4 md:space-y-0 md:space-x-8">
//         {/* <img
//           src={logo}
//           alt='bole logo'
//           className="w-24 h-24 md:w-40 md:h-40 lg:w-48 lg:h-48"
//         /> */}
//         <div className="text-center md:text-left">
//           <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 text-white">
//            {t('Welcome to Bole Sub City Administration!')}
//           </h2>
//         </div>
//       </div>

//       {/* Main Heading */}
//       <div className="text-center mb-10">
//         <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold  text-white">
//           {t('Where do you want to go')}?
//         </h1>
//       </div>

//       {/* Button Group */}
//       <div className="flex flex-wrap justify-center gap-6">
//         {buttons.map((button, index) => (
//           <a
//             key={index}
//             href={button.link}
//             className="flex items-center justify-center w-40 h-16 bg-[##0000FF] text-white font-semibold rounded-lg shadow-lg transition-transform transform hover:scale-110 md:w-44 md:h-18 lg:w-48 lg:h-20"
//             style={{ backgroundColor: button.color }}
//           >
//             <span className="mr-2 text-xl">{button.icon}</span>
//             {button.label}
//           </a>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default ButtonGroup;



import React from "react";
import {
  FaHome,
  FaServicestack,
  FaNewspaper,
  FaBullhorn,
  FaComment,
  FaRegGrinBeam,
  FaImage,
} from "react-icons/fa";
import logo from "../../assets/BOLELOGO.png";
import { useTranslation } from "react-i18next";

function ButtonGroup() {
  const { t } = useTranslation();
  const buttons = [
    {
      label: t("Where do you want to go"),
      color: "#1E3A8A",
      link: "/officedirection",
      icon: <FaHome />,
    },
    {
      label: t("Services"),
      color: "#1E3A8A",
      link: "/services",
      icon: <FaServicestack />,
    },
    {
      label: t("News"),
      color: "#1E3A8A",
      link: "/news",
      icon: <FaNewspaper />,
    },
    {
      label: t("Announcement"),
      color: "#1E3A8A",
      link: "/announcement",
      icon: <FaBullhorn />,
    },
    {
      label: t("Feedback"),
      color: "#1E3A8A",
      link: "/feedback",
      icon: <FaComment />,
    },
    {
      label: t("Compliant"),
      color: "#1E3A8A",
      link: "/complaint",
      icon: <FaRegGrinBeam />,
    },
    {
      label: t("Gallery"),
      color: "#1E3A8A",
      link: "/gallery",
      icon: <FaImage />,
    },
  ];

  return (
    <div className=" bg-gradient-to-r from-blue-500 to-indigo-700 min-h-screen flex flex-col items-center">
      {/* Logo and Heading Side by Side */}
      <div>
        <marquee className="text-2xl md:text-4xl lg:text-4xl text-white p-4 bg-[#0a4275] mt-4 rounded-lg font-bold" behavior="scroll" direction="left">
      {t('Welcome to Bole Sub-City! We are dedicated to providing exceptional service and support, ensuring your experience with us is both satisfying and memorable. Thank you for choosing us—your satisfaction is our priority.')}
        </marquee>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center mb-4 space-y-2 md:space-y-0 md:space-x-8">
        <img
          src={logo}
          alt="bole logo"
          className="w-24 h-24 md:w-36 md:h-36 lg:w-40 lg:h-40"
        />
        <div className="text-center md:text-left">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white">
            {t("Welcome to Bole Sub City Administration!")}
          </h2>
        </div>
      </div>

      {/* Main Heading */}

      <div className="text-center mb-24">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white tracking-wide">
          {t("Where do you want to go")}?
        </h1>
      </div>

      {/* Button Group */}
      <div className="flex flex-wrap justify-center gap-6">
        {buttons.map((button, index) => (
          <a
            key={index}
            href={button.link}
            className="flex items-center justify-center w-44 h-20 bg-opacity-90 text-white font-semibold rounded-xl shadow-lg transition-transform transform hover:scale-110 hover:shadow-2xl hover:bg-opacity-100 md:w-48 md:h-24 lg:w-56 lg:h-28"
            style={{ backgroundColor: button.color }}
          >
            <div className="bg-white bg-opacity-20 rounded-full p-2 mr-3">
              <span className="text-2xl">{button.icon}</span>
            </div>
            <span className="text-lg md:text-xl">{button.label}</span>
          </a>
        ))}
      </div>
    </div>
  );
}

export default ButtonGroup;
