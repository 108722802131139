// src/components/Navbar.js
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import LOGO from "../assets/BOLELOGO.png"; // Adjust the path to your logo
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import { Select } from "antd";

const { Option } = Select;

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (value) => {
    if (i18n.changeLanguage) {
      i18n.changeLanguage(value);
    } else {
      console.error('i18n.changeLanguage is not a function');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
    });
  }, []);

  return (
    <div className={`sticky top-0 z-50 bg-[#0a4275] ${isScrolled ? "shadow-lg" : "shadow-md"}`}>
      <header
        className={`flex items-center justify-between py-2 px-4 sm:px-10 font-[sans-serif] tracking-wide transition-all duration-300 ${
          isScrolled ? "py-2" : "py-4"
        }`}
      >
        <div className="flex items-center justify-between w-full gap-5">
          <NavLink to="/" className="flex items-center">
            <img
              src={LOGO}
              alt="logo"
              className={`transition-all duration-300 ${isScrolled ? "w-16 h-16" : "w-20 h-20"}`}
              data-aos="zoom-in"
            />
            <span className="hidden md:inline text-white font-bold px-2 text-xl md:text-2xl">
              {t("Bole Subcity")}
            </span>
          </NavLink>

          {/* Hamburger Icon for Mobile */}
          <button onClick={toggleMenu} className="lg:hidden">
            <svg
              className="w-7 h-7 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>

          {/* Mobile Menu */}
          <div className={`lg:hidden ${isMenuOpen ? "block" : "hidden"}`}>
            <ul className="flex flex-col items-start p-4 space-y-2 bg-white shadow-lg fixed inset-0 z-40 w-56">
              <li className="w-full text-right">
                <button onClick={closeMenu} className="text-black text-2xl">
                  &times;
                </button>
              </li>
              {[
                { to: "/", label: t('Home') },
                { to: "/officedirection", label: "OfficesDirection" },
                { to: "/services", label: "Services" },
                { to: "/news", label: "News" },
                { to: "/announcement", label: "Announcement" },
                { to: "/contact", label: "Contact" },
                { to: "/complaint", label: "Compliant" },
                { to: "/gallery", label: "Gallery" },
                { to: "/systems", label: "Systems" },
              ].map((item, index) => (
                <li key={index} className="w-full border-b border-gray-300">
                  <NavLink
                    to={item.to}
                    className={({ isActive }) =>
                      isActive ? "text-[#007bff] block py-2 px-3 text-gray-700 font-medium text-base" : "block py-2 px-3 text-gray-700 font-medium text-base"
                    }
                    onClick={closeMenu}
                  >
                    {t(item.label)}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          {/* Regular Menu - Visible on All Screens */}
          <div className="hidden lg:flex items-center space-x-6">
            {[
              { to: "/", label: "Home" },
              { to: "/officedirection", label: "OfficesDirection" },
              { to: "/services", label: "Services" },
              { to: "/news", label: "News" },
              { to: "/announcement", label: "Announcement" },
              { to: "/contact", label: "Contact" },
              { to: "/complaint", label: "Compliant" },
              { to: "/gallery", label: "Gallery" },
              { to: "/systems", label: "Systems" },
            ].map((item, index) => (
              <NavLink
                key={index}
                to={item.to}
                className={({ isActive }) =>
                  isActive ? "text-yellow-500 font-bold" : "text-white"
                }
              >
                {t(item.label)}
              </NavLink>
            ))}
          </div>

          {/* Language Selector */}
          <div className="flex items-center space-x-2">
            <span className="text-sm font-bold text-white">{t("LN")}</span>
            <Select
              defaultValue={i18n.language}
              value={i18n.language}
              onChange={changeLanguage}
              className="text-sm rounded-full font-bold bg-blue-600 text-white hover:bg-blue-700 focus:border-blue-800"
              dropdownClassName="bg-white border border-blue-600 rounded-lg"
            >
              {[
                { label: t("English"), value: "en" },
                { label: t("Amharic"), value: "am" },
                { label: t("Oromo"), value: "or" },
                { label: t("Somali"), value: "so" },
                { label: t("Afar"), value: "aa" },
                { label: t("Tigrinya"), value: "ti" },
              ].map(({ label, value }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
