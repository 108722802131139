import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Input, message, Table, Modal } from 'antd';
import 'tailwindcss/tailwind.css'; // Import Tailwind CSS

const OfficeServiceManager = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [offices, setOffices] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentOffice, setCurrentOffice] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchOffices();
  }, []);

  const fetchOffices = async () => {
    try {
      const response = await axios.get('https://bolesubcitypointer.api.ethioalert.com/api/officeservice');
      setOffices(response.data);
    } catch (error) {
      console.error('Error fetching offices:', error);
      message.error('Failed to fetch office data. Please try again.');
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (editId) {
        await axios.patch(`https://bolesubcitypointer.api.ethioalert.com/api/officeservice/${editId}`, {
          floor: values.floor,
          offices: values.offices.split(',').map((office) => office.trim())
        });
        message.success('Office service updated successfully!');
        setEditId(null);
      } else {
        await axios.post('https://bolesubcitypointer.api.ethioalert.com/api/officeservice', {
          floor: values.floor,
          offices: values.offices.split(',').map((office) => office.trim())
        });
        message.success('Office service added successfully!');
      }
      form.resetFields();
      fetchOffices();
    } catch (error) {
      console.error('Error submitting office service data:', error);
      message.error(editId ? 'Failed to update office service. Please try again.' : 'Failed to add office service. Please try again.');
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      floor: record.floor,
      offices: record.offices ? record.offices.join(', ') : '',
    });
    setCurrentOffice(record);
    setEditId(record._id);
    setIsModalVisible(true);
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      await axios.patch(`https://bolesubcitypointer.api.ethioalert.com/api/officeservice/${currentOffice._id}`, {
        floor: values.floor,
        offices: values.offices.split(',').map((office) => office.trim())
      });
      message.success('Office service updated successfully');
      setIsModalVisible(false);
      fetchOffices();
    } catch (error) {
      console.error('Validation failed:', error);
      message.error('Failed to update office service. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://bolesubcitypointer.api.ethioalert.com/api/officeservice/${id}`);
      message.success('Office service deleted successfully');
      fetchOffices();
    } catch (error) {
      console.error('Error deleting office service:', error);
      message.error('Failed to delete office service. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentOffice(null);
    setEditId(null);
  };

  const columns = [
    {
      title: 'Floor',
      dataIndex: 'floor',
      key: 'floor',
    },
    {
      title: 'Offices',
      dataIndex: 'offices',
      key: 'offices',
      render: (offices) => (offices ? offices.join(', ') : 'N/A'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className="flex space-x-2">
          <Button
            type="primary"
            onClick={() => handleUpdate(record)}
            className="bg-blue-500 hover:bg-blue-600 text-white"
          >
            Update
          </Button>
          <Button
            type="danger"
            onClick={() => handleDelete(record._id)}
            className="bg-red-500 hover:bg-red-600 text-white"
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg">
        <Form.Item name="floor" label="Name of the office" rules={[{ required: true, message: 'Please input the floor!' }]}>
          <Input placeholder="Enter floor number" className="rounded-md border-gray-300" />
        </Form.Item>
        <Form.Item name="offices" label="Services of the office (comma separated)" rules={[{ required: true, message: 'Please input the offices!' }]}>
          <Input placeholder="Enter offices" className="rounded-md border-gray-300" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="bg-green-500 hover:bg-green-600">
            Submit
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={columns}
        dataSource={offices}
        rowKey="_id"
        className="mt-6"
        pagination={{ pageSize: 10 }}
        bordered
        size="middle"
      />

      <Modal
        title="Update Office Service"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleUpdateSubmit}
        className="text-center"
        footer={[
          <Button key="cancel" onClick={handleCancel} className="bg-gray-300 hover:bg-gray-400">
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleUpdateSubmit} className="bg-blue-500 hover:bg-blue-600">
            Update
          </Button>,
        ]}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item name="floor" label="Floor" rules={[{ required: true, message: 'Please input the floor!' }]}>
            <Input placeholder="Enter floor number" className="rounded-md border-gray-300" />
          </Form.Item>
          <Form.Item name="offices" label="Offices (comma separated)" rules={[{ required: true, message: 'Please input the offices!' }]}>
            <Input placeholder="Enter offices" className="rounded-md border-gray-300" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OfficeServiceManager;
