import React from 'react'
import Compliantt from "../components/Compliant/Compliant"

function Complaint() {
  return (
    <div>

      <Compliantt />
    </div>
  )
}

export default Complaint