import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const OfficeList = () => {
  const { t } = useTranslation();
  const [offices, setOffices] = useState([]);
  const [openFloor, setOpenFloor] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await axios.get('https://bolesubcitypointer.api.ethioalert.com/api/officeservice');
        console.log('Fetched offices:', response.data); // Debugging line
        setOffices(response.data);
      } catch (error) {
        console.error('Error fetching office data:', error);
        setError('Failed to fetch office data. Please try again later.');
      }
    };

    fetchOffices();
  }, []);

  const toggleFloor = (floorId) => {
    setOpenFloor(openFloor === floorId ? null : floorId);
  };

  return (
    <div className="p-6 bg-gray-300 min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-center text-blue-700">{t('Services provided by offices in the district')}</h1>
      {error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : (
        <div className="space-y-4">
          {offices.length === 0 ? (
            <p className="text-center text-gray-700">No offices available</p>
          ) : (
            offices.map((office) => (
              <div
                key={office._id}
                className={`rounded-lg shadow-lg transition-all duration-300 border ${openFloor === office._id ? 'border-blue-500' : 'border-gray-300'} bg-${openFloor === office._id ? 'blue-50' : 'white'}`}
              >
                <button
                  onClick={() => toggleFloor(office._id)}
                  className={`w-full text-left text-xl font-semibold flex justify-between items-center p-4 rounded-t-lg transition-colors duration-300 ${openFloor === office._id ? 'bg-blue-800 text-white' : 'bg-gray-800 text-white hover:bg-gray-600'}`}
                >
                  {office.floor}
                  <svg
                    className={`w-6 h-6 transition-transform ${openFloor === office._id ? 'rotate-180' : 'rotate-0'}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                {openFloor === office._id && (
                  <ul className="list-disc list-inside pl-6 py-4 bg-blue-50">
                    {office.offices.length > 0 ? (
                      office.offices.map((officeName, index) => (
                        <li key={index} className="mb-2 text-gray-700">{officeName}</li>
                      ))
                    ) : (
                      <li className="text-gray-600">No offices listed for this floor</li>
                    )}
                  </ul>
                )}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default OfficeList;
