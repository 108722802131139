import React from 'react';
import { useTranslation } from "react-i18next";

const OfficeDetailsPage = () => {
    const { t } = useTranslation();
  return (
    <div className="px-6 py-10 bg-gradient-to-r from-blue-50 to-blue-100">
      {/* Summary Section */}
      <div className="max-w-7xl mx-auto mb-12 text-center bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-5xl font-extrabold text-blue-800 mb-6 tracking-wide">
          {t('About Bole Subcity')}
        </h2>
        <div className="relative mb-6">
          <div className="absolute inset-0 bg-blue-200 opacity-30 rounded-lg transform skew-y-6"></div>
          <p className="relative text-xl text-gray-800 leading-relaxed max-w-2xl mx-auto">
            {t(
              'Bole Subcity Bole sub-town is one of the eleven sub-towns in Addis Ababa city. The sub-city is the international gateway of our Addis Ababa, the seat of many great institutions, including our national airline, which is the pride of Ethiopia, embassies, international and continental organizations are located, where the modernity and solidarity of our city can be seen brightly.'
            )}
          </p>
        </div>
        <div className="relative">
          <div className="absolute inset-0 bg-blue-200 opacity-30 rounded-lg transform skew-y-6"></div>
          <p className="relative text-xl text-gray-800 leading-relaxed max-w-2xl mx-auto">
            {t(
              'Bole sub-district has an area of 6,371 hectares and is divided into eleven districts, with a population of 308,995. The administration of our sub-city is working diligently at every level to increase public satisfaction by improving and modernizing the services provided to the residents, and I would like to confirm that we are working hard to increase your satisfaction with the services you receive in our sub-city.'
            )}
          </p>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="max-w-7xl mx-auto mb-12 text-center">
        <button className="bg-blue-700 text-white font-bold py-3 px-6 rounded-full shadow-md hover:bg-blue-800 transition-colors duration-300">
          {t('Learn More About Bole Subcity')}
        </button>
      </div>

      {/* Additional Features Section */}
      <div className="max-w-7xl mx-auto mb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Feature Cards */}
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
          <h3 className="text-2xl font-semibold text-blue-800 mb-4">{t('Iconic Landmarks')}</h3>
          <p className="text-gray-700">
            {t('Discover the landmarks that make Bole Subcity a unique and vibrant place to live and work.')}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
          <h3 className="text-2xl font-semibold text-blue-800 mb-4">{t('Community Services')}</h3>
          <p className="text-gray-700">
            {t('Engage with various community services designed to enhance the quality of life for our residents.')}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
          <h3 className="text-2xl font-semibold text-blue-800 mb-4">{t('Modern Facilities')}</h3>
          <p className="text-gray-700">
            {t('Experience state-of-the-art facilities that cater to all your professional and personal needs.')}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
          <h3 className="text-2xl font-semibold text-blue-800 mb-4">{t('Green Spaces')}</h3>
          <p className="text-gray-700">
            {t('Enjoy beautifully maintained parks and green spaces ideal for relaxation and community activities.')}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
          <h3 className="text-2xl font-semibold text-blue-800 mb-4">{t('Public Transportation')}</h3>
          <p className="text-gray-700">
            {t('Access efficient and reliable public transportation options connecting you to the rest of the city.')}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
          <h3 className="text-2xl font-semibold text-blue-800 mb-4">{t('Cultural Centers')}</h3>
          <p className="text-gray-700">
            {t('Visit local cultural centers to immerse yourself in the rich heritage and vibrant arts scene of Addis Ababa.')}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
          <h3 className="text-2xl font-semibold text-blue-800 mb-4">{t('Educational Institutions')}</h3>
          <p className="text-gray-700">
            {t('Benefit from a range of educational institutions, from primary schools to universities, located within Bole Subcity.')}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
          <h3 className="text-2xl font-semibold text-blue-800 mb-4">{t('Healthcare Facilities')}</h3>
          <p className="text-gray-700">
            {t('Access top-tier healthcare services at various hospitals and clinics throughout the subcity.')}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
          <h3 className="text-2xl font-semibold text-blue-800 mb-4">{t('Business Hubs')}</h3>
          <p className="text-gray-700">
            {t('Explore thriving business hubs that offer numerous opportunities for entrepreneurship and employment.')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OfficeDetailsPage;
