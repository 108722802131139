import React from 'react'
import Announcementt from "../components/Announcement/Announcement"

function Announcement() {
  return (
    <div>
      <Announcementt / >
    </div>
  )
}

export default Announcement