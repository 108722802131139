// src/components/BoleJobPortal.js
import React from 'react';

const BoleJobPortal = () => {
  return (
    <div className="min-h-screen">
      <iframe 
        src="https://bole.job.portal.qualiberentertainmentplc.com/" 
        title="Bole Job Portal" 
        width="100%" 
        height="100%"
        className="border-none h-screen"
      ></iframe>
    </div>
  );
}

export default BoleJobPortal;








// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Card, Row, Col, Typography, Skeleton, Modal, Button } from 'antd';
// import { useTranslation } from "react-i18next";
// const { Title, Paragraph } = Typography;

// const AnnouncementDisplay = () => {
//   const { t } = useTranslation();
//   const [announcements, setAnnouncements] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [currentAnnouncement, setCurrentAnnouncement] = useState(null);

//   useEffect(() => {
//     fetchAnnouncements();
//   }, []);

//   const fetchAnnouncements = async () => {
//     try {
//       const response = await axios.get('https://bolesubcitypointer.api.ethioalert.com/api/announcements');
//       setAnnouncements(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching announcements:', error);
//     }
//   };

//   const showModal = (announcement) => {
//     setCurrentAnnouncement(announcement);
//     setIsModalVisible(true);
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//     setCurrentAnnouncement(null);
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <Title level={2} className="text-center mb-8">{t('Important Announcements')}</Title>
//       <Row gutter={[16, 16]}>
//         {loading ? (
//           Array.from({ length: 6 }).map((_, index) => (
//             <Col xs={24} sm={12} md={8} lg={6} key={index}>
//               <Skeleton active>
//                 <Card loading={true} />
//               </Skeleton>
//             </Col>
//           ))
//         ) : (
//           announcements.map((item) => (
//             <Col xs={24} sm={12} md={8} lg={6} key={item._id}>
//               <Card
//                 hoverable
//                 cover={
//                   <img
//                     alt={item.title}
//                     src={`https://bolesubcitypointer.api.ethioalert.com/${item.image}`}
//                     className="object-cover h-48"
//                   />
//                 }
//                 className="rounded-lg shadow-lg border-primary-500"
//                 style={{ borderColor: '#1890ff' }}
//               >
//                 <Title level={4} className="truncate">{item.title}</Title>
//                 <Paragraph ellipsis={{ rows: 3 }} className="italic text-gray-700">{item.description}</Paragraph>
//                 <Button type="primary" onClick={() => showModal(item)} className="mt-4">
//                   View Details
//                 </Button>
//               </Card>
//             </Col>
//           ))
//         )}
//       </Row>
//       {currentAnnouncement && (
//         <Modal
//           title={currentAnnouncement.title}
//           visible={isModalVisible}
//           onCancel={handleCancel}
//           footer={null}
//           centered
//         >
//           <img
//             alt={currentAnnouncement.title}
//             src={`https://bolesubcitypointer.api.ethioalert.com/${currentAnnouncement.image}`}
//             className="w-full h-64 object-cover mb-4 rounded"
//           />
//           <Paragraph>{currentAnnouncement.description}</Paragraph>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default AnnouncementDisplay;
