import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Announcement from './pages/Announcement';
import Complaint from './pages/Complaint';
import Feedback from './pages/Feedback';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import News from './pages/News';
import OfficesDirection from './pages/OfficesDirection';
import Services from './pages/Services';
import ErrorPage from './pages/ErrorPage';
import MainLayout from './pages/MainLayout';
import ProtectedRoute from './components/Admin/ProtectedRoute'; // Ensure ProtectedRoute is defined
import NavbarA from './components/Admin/NavbarA'; // Import NavbarA
import AuthHandler from './components/Admin/AuthHandler'; // Import AuthHandler
import Systems from './pages/Systems';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState("");

  // Check authentication status on initial load
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Verify token or fetch user role here if needed
      setIsAuthenticated(true);
      // Optionally fetch user role or decode token to set userRole
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        { path: "/", element: <Home /> },
        { path: "officedirection", element: <OfficesDirection /> },
        { path: "services", element: <Services /> },
        { path: "complaint", element: <Complaint /> },
        { path: "feedback", element: <Feedback /> },
        { path: "news", element: <News /> },
        { path: "gallery", element: <Gallery /> },
        { path: "contact", element: <Contact /> },
        { path: "systems", element: <Systems /> },
        { path: "announcement", element: <Announcement /> },
        {
          path: "adminpage",
          element: (
            <ProtectedRoute
              element={<NavbarA />}
              isAuthenticated={isAuthenticated}
              userRole={userRole}
            />
          ),
        },
        { path: "login", element: <AuthHandler onLogin={(role) => { setIsAuthenticated(true); setUserRole(role); }} /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
