import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const systems = [
  {
    name: 'Bole Subcity Website',
    link: 'https://bolesubcityadministration.gov.et/',
    purpose: 'bole_subcity_website_purpose', // Use keys for dynamic localization
  },
  {
    name: 'Land Administration Office',
    link: 'https://www.addisland.gov.et/en-us/',
    purpose: 'land_administration_office_purpose',
  },
  {
    name: 'Bole Subcity ITDO Website',
    link: 'https://boleitdo.ethioalert.com/',
    purpose: 'e_trade_purpose',
  },
  {
    name: 'Bole Sub-City Trade Office',
    link: 'https://boletradeoffice.codenilesolutions.com/',
    purpose: 'bole_subcity_trade_office_purpose',
  },
  {
    name: 'Job Portal for Labour and Skill',
    link: 'https://bole.job.portal.qualiberentertainmentplc.com/',
    purpose: 'job_portal_for_labour_and_skill_purpose',
  },
  {
    name: 'Compliance Form',
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSfI0ObRht27ptA4ELbHNHWP2yzwp3_ggeawlp83rlC8wrqZLQ/viewform?pli=1&pli=1',
    purpose: 'compliance_form_purpose',
  },
  {
    name: 'IT request Form',
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSfI0ObRht27ptA4ELbHNHWP2yzwp3_ggeawlp83rlC8wrqZLQ/viewform?pli=1&pli=1',
    purpose: 'it_request_form_purpose',
  },
  {
    name: 'Smart Office',
    link: 'https://www.smart.aaca.gov.et/sites/bole/',
    purpose: 'smart_office_purpose',
  },
];

const SystemsPage = () => {
  const { t } = useTranslation(); // Use the hook to access translation function

  return (
    <div className="bg-gray-300 min-h-screen p-8">
      {/* Header Section */}
      <header className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">{t('bole_subcity_systems_title')}</h1>
        <p className="text-gray-600 mt-2">{t('bole_subcity_systems_description')}</p>
      </header>

      {/* Systems List Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {systems.map((system, index) => (
          <div
            key={index}
            className="bg-slate-600 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
          >
            <h2 className="text-2xl font-semibold text-white mb-2">{t(system.name)}</h2>
            <p className="text-white mb-4">{t(system.purpose)}</p>
            <a
              href={system.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-500 font-semibold hover:text-yellow-600 transition-colors duration-200"
            >
              {t('visit_system')}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SystemsPage;
