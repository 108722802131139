import React, { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, Typography, message } from 'antd';

const { Title, Text } = Typography;
const { TextArea } = Input;

const FeedbackPage = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await axios.post('https://bolesubcitypointer.api.ethioalert.com/api/feedback', values);
      message.success('Feedback submitted successfully');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      message.error('Failed to submit feedback');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <Title level={2} className="text-center mb-4">We Value Your Feedback</Title>
        <Form
          name="feedback"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label={<Text strong>Name</Text>}
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input placeholder="Your Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label={<Text strong>Email</Text>}
            rules={[{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email' }]}
          >
            <Input placeholder="Your Email" />
          </Form.Item>
          <Form.Item
            name="message"
            label={<Text strong>Message</Text>}
            rules={[{ required: true, message: 'Please enter your message' }]}
          >
            <TextArea rows={4} placeholder="Your Message" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} className="w-full mt-4">
              Submit Feedback
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default FeedbackPage;
