import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  FaHome,
  FaNewspaper,
  FaBullhorn,
  FaImages,
  FaFileAlt,
  FaBriefcase,
  FaCalendarAlt,
  FaCommentDots,
  FaEnvelope,
  FaSignOutAlt,
} from "react-icons/fa";

import AddNews from "./AddNews";
import AddDirectorMessage from "./AddDirectorMessage";
import ImageUpload from "./ImageUpload";
import AddTenders from "./AddTenders";
import AddVacancy from "./AddVacancy";
import AddEvent from "./AddEvent";
import ComplaintsList from "./ComplaintsList";
import ViewFeedback from "./ViewFeedback";
import AdminWelcomePage from "./AdminWelcomePage";
import QuickMessage from "./QuickMessage";
import AddCabinet from "./AddCabinet";
import UserManager from "./UserManager";
import OfficeManager from "./OfficeManager";
import AddAnnouncement from "./AddAnnouncement"

const NavbarA = () => {
  const [currentPage, setCurrentPage] = useState("home");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const navigateToPage = (page) => {
    setCurrentPage(page);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-[#b7bfc5] text-white">
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="flex flex-col space-y-2 w-full sm:w-72 bg-[#0a4275] text-white">
          <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "home"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("home")}
          >
            <FaHome className="mr-2" /> Home
          </a>
          {/* <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "adddirector"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("adddirector")}
          >
            <FaBullhorn className="mr-2" /> Add Director Message
          </a> */}
          <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "addnews"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("addnews")}
          >
            <FaNewspaper className="mr-2" /> Add News
          </a>

          <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "addannouncement"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("addannouncement")}
          >
            <FaNewspaper className="mr-2" /> Add Announcement
          </a>
          {/* <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "addcabinet"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("addcabinet")}
          >
            <FaBriefcase className="mr-2" /> Add Cabinet
          </a> */}
          <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "addgallery"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("addgallery")}
          >
            <FaImages className="mr-2" /> Add Gallery
          </a>
          {/* <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "addtenders"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("addtenders")}
          >
            <FaFileAlt className="mr-2" /> Add Tenders
          </a> */}
          {/* <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "addvacancy"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("addvacancy")}
          >
            <FaBriefcase className="mr-2" /> Add Vacancy
          </a> */}
          {/* <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "addevent"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("addevent")}
          >
            <FaCalendarAlt className="mr-2" /> Add Event
          </a> */}


          <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "officemanager"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("officemanager")}
          >
            <FaCalendarAlt className="mr-2" /> Add Office Floor
          </a>
          <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "addquickmessage"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("addquickmessage")}
          >
            <FaCalendarAlt className="mr-2" /> Add Office Service
          </a>
          <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "viewfeedback"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("viewfeedback")}
          >
            <FaCommentDots className="mr-2" /> View Feedback
          </a>
          <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "viewcomplaints"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("viewcomplaints")}
          >
            <FaEnvelope className="mr-2" /> View Complaints
          </a>
          <a
            href="#"
            className={`flex items-center px-6 py-3 text-lg font-semibold ${
              currentPage === "usermanager"
                ? "text-yellow-500 bg-[#0a4275]"
                : "text-white hover:bg-[#084c77]"
            }`}
            onClick={() => navigateToPage("usermanager")}
          >
            <FaEnvelope className="mr-2" /> Add User
          </a>
          <a
            href="#"
            className="flex items-center px-6 py-3 text-lg font-semibold text-white hover:bg-[#084c77]"
            onClick={handleLogout}
          >
            <FaSignOutAlt className="mr-2" /> Logout
          </a>
        </div>
        <div className="flex-grow p-6">
          {currentPage === "home" && <AdminWelcomePage />}
          {currentPage === "addnews" && <AddNews />}
          {currentPage === "addannouncement" && <AddAnnouncement />}
          {/* {currentPage === "adddirector" && <AddDirectorMessage />} */}
          {currentPage === "addgallery" && <ImageUpload />}
          {/* {currentPage === "addtenders" && <AddTenders />}
          {currentPage === "addvacancy" && <AddVacancy />}
          {currentPage === "addevent" && <AddEvent />} */}
          {currentPage === "viewcomplaints" && <ComplaintsList />}
          {currentPage === "viewfeedback" && <ViewFeedback />}
          {currentPage === "addquickmessage" && <QuickMessage />}
          {/* {currentPage === "addcabinet" && <AddCabinet />} */}
          {currentPage === "usermanager" && <UserManager />}
          {currentPage === "officemanager" && <OfficeManager />}
        </div>
      </div>
    </div>
  );
};

export default NavbarA;
