import React from 'react'
import Galleryy from "../components/Gallery/gallery"

function Gallery() {
  return (
    <div>

      <Galleryy />
    </div>
  )
}

export default Gallery